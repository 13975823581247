body {
  background: #e7e7e6;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  line-height: 1.0;
  letter-spacing: -0.6px;
}
a * {
  pointer-events: none;
}
.flex.rows {
  display: flex;
  flex-direction: column;
}
.flex.rows.reverse {
  flex-direction: column-reverse;
}
.flex.cols,
.flex.columns {
  display: flex;
  flex-direction: row;
}
.flex.cols.reverse,
.flex.columns.reverse {
  flex-direction: row-reverse;
}
.flex.valign-top {
  align-items: flex-start;
}
.flex.valign-center {
  align-items: center;
}
.flex.valign-bottom {
  align-items: flex-end;
}
.flex.grow,
.flex.grow-1 {
  flex-grow: 1;
}
.flex.grow-2 {
  flex-grow: 2;
}
.flex.grow-3 {
  flex-grow: 3;
}
.bottom-align-row {
  display: flex;
  align-items: baseline;
}
.sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
}
.sidebar-container #sidebar-show {
  display: none;
}
.sidebar-container .sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  max-width: 300px;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-100%, 0);
  background: #007dc1;
}
.sidebar-container .sidebar .closer label {
  margin: 0;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  padding: .75rem;
  border: 1px solid transparent;
  border-radius: 2px;
}
.sidebar-container .sidebar .closer label:hover {
  border-color: #fff;
}
.sidebar-container .sidebar .closer label:active {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
}
.sidebar-container .sidebar ul.nav {
  margin: 0;
  justify-content: center;
}
.sidebar-container .sidebar ul.nav li {
  flex-grow: 1;
  text-align: center;
  font-size: 24px;
}
.sidebar-container .sidebar ul.nav li a {
  color: #fff;
}
.sidebar-container #sidebar-show:checked + .sidebar {
  transform: translate(0, 0);
  box-shadow: 0 0 15px #00000057;
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
  main {
    margin: 0 !important;
  }
  header .nav {
    display: none !important;
  }
  header .searchrow {
    flex-wrap: nowrap;
  }
  header .searchrow .col {
    display: none;
  }
  header .searchrow .search {
    flex-grow: 1;
  }
}
.topbanner {
  padding: 2rem;
  text-align: center;
}
.topbanner a.banner img {
  max-width: 100%;
}
header {
  background: #007dc1;
}
header #header-brand {
  width: 75%;
  max-width: 900px;
}
header .search {
  width: 300px;
  margin: 0 15px;
}
header .hamburger {
  cursor: pointer;
  color: #fff;
  margin: 0 15px 0 0;
  display: flex;
  align-items: center;
  padding: 0 .75rem;
  border: 1px solid transparent;
  border-radius: 2px;
}
header .hamburger:hover {
  border-color: #fff;
}
header .hamburger:active {
  border-color: #fff;
  background: #005c8e;
}
header .partners {
  margin-right: -15px;
  margin-left: -15px;
  background: #e7e7e6;
  overflow: hidden;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 60px;
}
header .partners:hover {
  max-height: initial;
}
header .partners .partner {
  height: auto;
  margin: 15px;
  max-height: 30px;
}
header .nav {
  background: #fff;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
header .nav li {
  flex-grow: 1;
  text-align: center;
  font-size: 24px;
}
header .nav li a {
  color: #666;
}
.ticker {
  background: #007dc1;
  color: #fff;
}
.ticker .left,
.ticker .right {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ticker .left:hover,
.ticker .right:hover {
  background: rgba(255, 255, 255, 0.25);
}
.ticker .left i,
.ticker .right i {
  font-size: 90px;
  line-height: 90px;
  text-align: center;
  vertical-align: middle;
}
.ticker .ticker-content {
  padding: 15px 0;
}
.ticker .ticker-content .news {
  padding: 15px;
  max-height: 8em;
  overflow: hidden;
}
.ticker .ticker-content .news a {
  color: #fff;
  opacity: .7;
}
.ticker .ticker-content .news a:hover {
  color: #fff;
  opacity: 1;
  text-decoration: none;
}
.ticker .ticker-content .news .category {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
.ticker .ticker-content .news .category a {
  opacity: 1;
}
.ticker .ticker-content .news .text {
  font-size: 18px;
  line-height: 1.3em;
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
  .ticker .left i,
  .ticker .right i {
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    vertical-align: middle;
  }
}
main {
  margin: 0 2.5rem 2.5rem;
  justify-content: center;
}
main .banner-block-left,
main .banner-block-right {
  background: #e7e7e6;
}
main .site-content {
  background: #fff;
  padding: 2.5rem;
  max-width: 1140px;
}
@media (max-width: 480px) {
  main .site-content {
    padding: 0;
  }
}
.category-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #007dc1;
  font-weight: 600;
  text-transform: uppercase;
}
.slideshow {
  background: #e7e7e6;
  color: #333;
}
.slideshow .image {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 350px;
}
.slideshow .image img {
  width: 100%;
}
.slideshow .image .dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  text-align: center;
}
.slideshow .image .dots .dot {
  cursor: pointer;
  background: #007dc1;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin: 0 6px;
}
.slideshow .image .dots .dot.active {
  background: #fff;
}
.slideshow .info .text {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slideshow .left,
.slideshow .right {
  color: #007dc1;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.slideshow .left:hover,
.slideshow .right:hover {
  background: rgba(255, 255, 255, 0.25);
}
.slideshow .left i,
.slideshow .right i {
  font-size: 90px;
  line-height: 90px;
  text-align: center;
  vertical-align: middle;
}
.content-cols {
  margin-top: 2.5rem;
}
@media (max-width: 480px) {
  .content-cols {
    margin: 2.5rem 0 0;
  }
}
.sidebar-col {
  padding-top: 15px;
}
@media (min-width: 992px) {
  .sidebar-col {
    margin-left: 2.5rem;
  }
}
article .image {
  display: flex;
  align-items: center;
  height: 350px;
  overflow: hidden;
  position: relative;
}
article .image a,
article .image img {
  width: 100%;
}
article h1 {
  margin-bottom: 2rem;
}
article h1 a {
  color: #000;
}
article .image-info {
  font-size: 12px;
}
article .image-info .source {
  text-align: right;
}
article .article-info {
  color: #007dc1;
  font-weight: 600;
  margin: 2.5rem 0;
  text-transform: uppercase;
}
article p.intro {
  font-weight: 600;
  font-size: 115%;
}
article .paragraph-header {
  font-weight: 600;
}
.article-info-box {
  background: #e7e7e6;
  padding: 1rem;
}
.article-info-box .title {
  font-weight: 600;
  font-size: 115%;
}
.article-hero {
  margin-bottom: 2.5rem;
}
.article-hero .category {
  font-size: 1.5rem;
  font-weight: 700;
  color: #007dc1;
  text-transform: uppercase;
}
.article-hero .image {
  display: flex;
  align-items: center;
  height: 350px;
  overflow: hidden;
  position: relative;
}
.article-hero .image a,
.article-hero .image img {
  width: 100%;
}
.article-hero .title {
  margin-top: .5rem;
  margin-bottom: 1rem;
}
.article-hero .title a {
  color: #000;
}
.article-hero .text {
  font-size: 15px;
}
.article-summary {
  margin-bottom: 2.5rem;
}
.article-summary .category {
  font-size: 1.5rem;
  font-weight: 700;
  color: #007dc1;
  text-transform: uppercase;
}
.article-summary .image {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* 16:9 Aspect */
  height: 0;
  padding-top: 56.25%;
}
.article-summary .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.article-summary .image a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.article-summary .title {
  margin-top: .5rem;
  margin-bottom: 1rem;
}
.article-summary .title a {
  color: #000;
}
.article-summary .text {
  font-size: 15px;
}
.article-highlight {
  padding: 15px;
  margin: -15px;
  background: #e7e7e6;
  margin-bottom: 2.5rem;
}
.article-highlight .category {
  font-size: 1.25rem;
  font-weight: 700;
  color: #007dc1;
  text-transform: uppercase;
}
.article-highlight .image {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  height: 125px;
  overflow: hidden;
  position: relative;
}
.article-highlight .image a,
.article-highlight .image img {
  width: 100%;
}
.article-highlight .title a {
  color: #000;
}
.article-highlight .text {
  font-size: 15px;
}
.article-highlight .more {
  font-weight: 600;
}
@media (max-width: 480px) {
  .article-highlight {
    margin: 0 0 1px;
  }
}
footer .top {
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7e7e6;
}
footer .top img {
  max-width: 90vw;
}
footer .bottom {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e7e7e6;
  padding: 1.5rem 1rem .5rem;
}
